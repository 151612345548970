import React from "react"

import { Layout, SEO, Intro, About, Features, Media } from "../components"
import { getContentFromAllFiles } from "../utils/content"
import { pageTypes } from "../types"

const HomePage = ({ data, pageContext: { locale } }) => {
  const siteContent = getContentFromAllFiles(data, 'site');
  return (
    <Layout contentPage={false} locale={locale} content={siteContent}>
      <SEO 
        title={siteContent.title}
        isHome
        description={siteContent.description}
        path=""
        lang={locale}
      />
      <Intro {...getContentFromAllFiles(data, 'intro')} />
      <About {...getContentFromAllFiles(data, 'about')} />
      <Media {...getContentFromAllFiles(data, 'media')} />
      <Features {...getContentFromAllFiles(data, 'features')}/>
    </Layout>
  )
}


export const pageQuery = graphql`
  query homePageQuery($locale: String) {
    allFile(filter: {relativeDirectory: {eq: $locale}}) {
      edges {
        node {
          name
          childMarkdownRemark {
            html
            frontmatter {
              title
              subTitle
              description
            }
            ...Intro
            ...Media
            ...Site
          }
        }
      }
    }
  }
`

HomePage.propTypes = pageTypes

export default HomePage
